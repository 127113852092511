import React from 'react';

import EntryPoints from '../EntryPoints';

const Home = () => {
    return (
        <div>
            <EntryPoints />
        </div>
    );
};

Home.propTypes = {};

export default Home;
